import { useEffect } from "react";

import { setCMPBannerVisible } from "@/app/actions.mjs";
import { didInitConsent } from "@/feature-consent/consent.mjs";
import globals from "@/util/global-whitelist.mjs";

export function useObserveCMPBannerVisibility() {
  useEffect(() => {
    const observer =
      typeof MutationObserver !== "undefined"
        ? new MutationObserver(() => {
            didInitConsent.then(() => {
              setTimeout(() => {
                const cmpVisibility = !!globals.document
                  ?.querySelector(".qc-cmp2-main")
                  ?.hasChildNodes();
                setCMPBannerVisible(cmpVisibility);
              }, 500);
            });
          })
        : null;

    if (observer) {
      observer.observe(globals.document.body, {
        childList: true,
        subtree: true,
      });
    }

    return () => observer?.disconnect();
  }, []);
}
