import { IS_NODE } from "@/__main__/constants.mjs";
import { setupConsent, teardownConsent } from "@/feature-consent/consent.mjs";
import { useObserveCMPBannerVisibility } from "@/feature-consent/consent-hook.mjs";
import mapOriginalRefs from "@/util/map-original-refs.mjs";
import cmpRefs from "@/util/use-observe-cmp-banner-visibility.mjs";

const original = mapOriginalRefs({
  cmpRefs,
});

export function setup() {
  if (IS_NODE) return;
  setupConsent();
  original.set({
    cmpRefs: {
      useObserveCMPBannerVisibility,
    },
  });
}

export function teardown() {
  if (IS_NODE) return;
  teardownConsent();
  original.restore();
}
